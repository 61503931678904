import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {},
  dialog: {
    bg: '#2A2A34',
    color: 'primaryText',
    marginBottom: '0',
    p: '32px 16px',
    flexDirection: 'column',
    borderRadius: { base: '16px 16px 0 0', md: '24px' },
    maxWidth: { base: '--chakra-sizes-md', md: '500px', lg: '650px' },
  },
  dialogContainer: {
    alignItems: { base: 'flex-end', md: 'center' },
  },
  body: {
    p: '16px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  header: {
    p: '20px',
  },
  footer: {
    marginTop: '40px',
    p: '0',
    gap: '12px',
  },
});

export const Modal = defineMultiStyleConfig({
  baseStyle,
});
