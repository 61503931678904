import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { API_BASE_URL } from '@/utils/constants';
import { linkToGZ } from '@/utils/index';
import { getStorageItem } from '@/utils/localStorage';

export const prepareHeaders = (headers: Headers) => {
  const jwt = getStorageItem<string>(StorageKeysEnum.jwt);

  if (jwt) {
    headers.set('authorization', `Bearer ${jwt}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders,
});
export const baseQueryWithRedirect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const lang = getStorageItem<string>(StorageKeysEnum.i18nextLng);
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    localStorage.clear();
    window.location.assign(linkToGZ(lang));
  }
  return result;
};
