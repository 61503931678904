import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import useMediaQuery from '@/hooks/useMediaQuery.hook';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { RoutesEnum } from '@/enums/routes.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

export const useSettings = () => {
  const { pathname } = useLocation();
  const { value: lang, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );
  const [isMobile, isTablet, isDesktop] = useMediaQuery([
    MediaQueriesEnum.isMobile,
    MediaQueriesEnum.isTablet,
    MediaQueriesEnum.isDesktop,
  ]);

  const pagesWithBackButton = useMemo(
    () => [RoutesEnum.game, RoutesEnum.rules].includes(pathname as RoutesEnum),
    [pathname],
  );

  const isPageWithoutNavigation = useMemo(
    () => [RoutesEnum.game, RoutesEnum.lock].includes(pathname as RoutesEnum),
    [pathname],
  );

  const isPageWithoutLayout = useMemo(
    () => [RoutesEnum.lock].includes(pathname as RoutesEnum),
    [pathname],
  );

  const isLockScreen = pathname === RoutesEnum.lock;
  const isGameScreen = pathname === RoutesEnum.game;

  const isTopBannerHidden = [
    RoutesEnum.lock,
    RoutesEnum.game,
    RoutesEnum.play,
    RoutesEnum.help,
    RoutesEnum.recent,
  ].includes(pathname as RoutesEnum);

  const isTopBannerVisible = !isTopBannerHidden;

  return {
    isMobile,
    isTablet,
    isDesktop,
    pagesWithBackButton,
    isPageWithoutNavigation,
    isPageWithoutLayout,
    isTopBannerVisible,
    isLockScreen,
    isGameScreen,
    lang,
    setLang,
  };
};
