import { Helmet, HelmetProvider } from 'react-helmet-async';

export const MetaProvider = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet></Helmet>
      </HelmetProvider>
    </>
  );
};
