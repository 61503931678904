import styled from 'styled-components';

export const Wrapper = styled('div')({
  background: '#171717',
  width: '250px',
  height: '100%',
  overflowY: 'auto',
});

export const Content = styled('div')(() => ({
  display: 'grid',
  gap: '4px',
}));
export const Bottom = styled('div')(({ theme }) => ({
  padding: `${theme.space(2)} ${theme.space(2.7)}`,
}));
