import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const List = styled('ul')(({ theme }) => ({
  marginTop: theme.space(2.3),
  display: 'grid',
  gap: '19px',
}));

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#979797',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '12px',
});

export const ToggleWrapper = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  color: 'white',
  right: '86px',
  top: '-1px',
});
