import { FC, Fragment, PropsWithChildren } from 'react';

import { Flex, Grid } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { colors } from '@/providers/StyledComponentsProvider/theme/colors';

import { trackEvent } from '@/hooks/useGoogleAnalytics';
import { useNavLinks } from '@/hooks/useNavLinks';
import { useSettings } from '@/hooks/useSettings.hook';

export const DesktopNavigationBlock = () => {
  const { topNavLinks } = useNavLinks();
  const { isPageWithoutNavigation } = useSettings();

  if (isPageWithoutNavigation) return null;

  return (
    <>
      <Flex height='62px' paddingTop='15px' width='100%'>
        <Grid
          gridColumnGap='3px'
          gridTemplateColumns={`repeat(${topNavLinks.length}, 1fr)`}
          width='100%'
        >
          {topNavLinks.map((item) => (
            <Fragment key={item.linkData.to as string}>
              <NavButton
                selected={item?.selected}
                {...item?.linkData}
                to={item.linkData.to}
              >
                {item?.icon && (
                  <div style={{ width: '39px' }}>{item?.icon}</div>
                )}
                {item?.label}
              </NavButton>
            </Fragment>
          ))}
        </Grid>
      </Flex>
    </>
  );
};

const NavButton: FC<PropsWithChildren & { selected: boolean; to: any }> = ({
  children,
  selected,
  to,
  ...rest
}) => {
  const onClick = () => {
    trackEvent('navigation_link_clicked', {
      link_url: to,
    });
  };

  return (
    <>
      <Flex
        _first={{
          borderRadius: '20px 0 0 0',
        }}
        _last={{
          borderRadius: '0 20px 0 0',
        }}
        alignItems='center'
        as={NavLink}
        background={selected ? colors.primaryYellow : colors.primaryBlue}
        color={colors.textDark}
        fontSize='14px'
        h='45px'
        justifyContent='center'
        onClick={onClick}
        textDecoration='none'
        textTransform='uppercase'
        w='100%'
        {...{ to }}
        {...rest}
        //   'svg path': {
        //     fill: selected ? 'white' : '#BDBDBD',
        //   },
      >
        {children}
      </Flex>
    </>
  );
};
