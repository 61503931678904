import React from 'react';

import { StyledBackground } from './Background.styles';
import { BackgroundProps } from './types';

const Background: React.FC<BackgroundProps> = ({ onCloseClick, isVisible }) => {
  if (!isVisible) return null;
  return <StyledBackground onClick={onCloseClick} />;
};

export default Background;
