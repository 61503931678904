export const colors = {
  white: '#FFFFFF',
  black: '#000000',

  primaryBlue: '#00B3D0',
  primaryYellow: '#EAE320',
  primaryGreen: '#23F4CF',
  textDark: '#121212',
  textGrey: '#C9C9D7',

  gradientBlueGreen: 'linear-gradient(112.95deg, #4BA0F0 0%, #23F4CF 100%)',
  gradientDurant:
    'linear-gradient(270deg, #01B3D0 0%, #01B182 35%, #5EBA4D 68.5%, #EAE321 100%)',
  gradientPocola:
    'linear-gradient(270deg, #FBF100 0%, #FE9202 30.5%, #F94548 68%, #FF0683 100%)',
};
