import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import ArrowLeft from '@/assets/icon/arrow-left.svg';

import { useNavLinks } from '@/hooks/useNavLinks';
import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

import { tKeys } from '@/i18n/resourses';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

const SidebarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.eerieBlack,
  color: theme.colors.white,
  height: '100vh',
  justifyContent: 'center',
  left: '0',
  position: 'fixed',
  top: '0',
  width: '112px',
}));
const SidebarInner = styled('div')(({ theme }) => ({
  display: 'grid',
  color: theme.colors.white,
  gap: '10px',
  gridTemplateRows: 'repeat(3, 1fr)',
}));
const LinkWrapper = styled(Link)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    color: selected ? theme.colors.primaryRed : theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: `${theme.colors.primaryRed} !important`,
    },
  }),
);
const LinkInner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '10px',
}));
const LinkText = styled('p')(({ theme }) => ({
  fontSize: '12px',
  marginTop: '5px',
  textAlign: 'center',
}));
const BackToPrevPage = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: '0',
  background: 'transparent',
  outline: '0',
  cursor: 'pointer',
  textDecoration: 'none',
  justifySelf: 'start',
  position: 'absolute',
  top: '20px',
  left: '0',
  zIndex: '1',
}));
const ChevronLeft = styled('img')({
  marginRight: '8px',
});
const BackToGameZoneText = styled('p')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '700',
  color: theme.colors.white,
}));

export const DesktopSideNavbar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop, pagesWithBackButton } = useSettings();
  const { sidebarNavLinks } = useNavLinks();

  return IS_HEADER_HIDDEN && isDesktop ? (
    <SidebarWrapper>
      {pagesWithBackButton && (
        <BackToPrevPage onClick={() => navigate(RoutesEnum.play)}>
          <ChevronLeft alt='' src={ArrowLeft} />
          <BackToGameZoneText>{t(tKeys.back)}</BackToGameZoneText>
        </BackToPrevPage>
      )}
      <SidebarInner>
        {sidebarNavLinks.map((item) => (
          <LinkWrapper
            key={item.linkData.to as string}
            {...item?.linkData}
            selected={item.selected}
          >
            <LinkInner>
              {item?.icon && (
                <div>{item?.renderIcon?.({ fill: 'currentColor' })}</div>
              )}
              <LinkText>{item?.label}</LinkText>
            </LinkInner>
          </LinkWrapper>
        ))}
      </SidebarInner>
    </SidebarWrapper>
  ) : null;
};
