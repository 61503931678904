import React from 'react';

import Loader from '../Loader';

const LoaderScreen: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '1999',
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
    >
      <Loader />
    </div>
  );
};

export default LoaderScreen;
