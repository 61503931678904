import { FC, ReactNode, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { LinkProps, useLocation } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import GameZone from '@/assets/icon/GameZone';
import HNL from '@/assets/icon/HNL';
import Logo from '@/assets/icon/Logo';

import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';

import { tKeys } from '@/i18n/resourses';

export interface IHeaderNavigation {
  label: string | ReactNode;
  icon?: ReactNode;
  renderIcon?: FC;
  selected: boolean;
  linkData: LinkProps;
}

export const useNavLinks = () => {
  const { t } = useTranslation();
  const { lang } = useSettings();
  const { pathname } = useLocation();

  const BingoButton: IHeaderNavigation = {
    label: t(tKeys.bingo),
    icon: <Logo />,
    renderIcon: (props: any) => <Logo {...props} />,
    selected: true,
    linkData: {
      to: RoutesEnum.play,
    },
  };

  const GamezoneButton: IHeaderNavigation = {
    label: <HNL />,
    icon: <GameZone />,
    renderIcon: (props: any) => <GameZone {...props} />,
    selected: false,
    linkData: {
      to: linkToGZ(lang),
    },
  };

  const sidebarNavLinks: IHeaderNavigation[] = [];

  const topNavLinks = useMemo<IHeaderNavigation[]>(
    () => [
      {
        label: t(tKeys.play),
        selected: pathname === RoutesEnum.play,
        linkData: {
          to: RoutesEnum.play,
        },
      },
      {
        label: t(tKeys.recent),
        selected: pathname === RoutesEnum.recent,
        linkData: {
          to: RoutesEnum.recent,
        },
      },
      {
        label: t(tKeys.howToPlay),
        selected: pathname === RoutesEnum.help,
        linkData: {
          to: RoutesEnum.help,
        },
      },
    ],
    [pathname, t],
  );

  return { sidebarNavLinks, topNavLinks };
};
