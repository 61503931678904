import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  GetOneGameRes,
  GetPageResponse,
  GetRecentGamesRes,
  GetTodayGamesRes,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    getTodayGames: builder.query<GetTodayGamesRes, void>({
      query: () => '/games-today',
      providesTags: [ApiTagsEnum.GET_TODAY_GAMES],
    }),
    getRecentGames: builder.query<GetRecentGamesRes, void>({
      query: () => '/games-recent',
      providesTags: [ApiTagsEnum.GET_RECENT_GAMES],
    }),
    getOneGame: builder.query<GetOneGameRes, { id: string }>({
      query: ({ id }) => `/games/${id}`,
      providesTags: [ApiTagsEnum.GET_ONE_GAME],
    }),
    signUp: builder.mutation<void, { id: number }>({
      query: ({ id }) => {
        const url = `/games/${id}/join`;
        return { url, method: 'POST' };
      },
    }),
    generateJWTByToken: builder.mutation<{ token: string }, { token: string }>({
      query: (body) => ({ url: '/login', method: 'POST', body }),
    }),
    getRules: builder.query<GetPageResponse, { lang: string }>({
      query: ({ lang }) => `/page/rules?lang=${lang}`,
    }),
    getAbbrRules: builder.query<GetPageResponse, { lang: string }>({
      query: ({ lang }) => `/page/abbr-rules?lang=${lang}`,
    }),
  }),
});

export const {
  useGetTodayGamesQuery,
  useGetRecentGamesQuery,
  useGetOneGameQuery,
  useSignUpMutation,
  useGenerateJWTByTokenMutation,
  useGetRulesQuery,
  useGetAbbrRulesQuery,
} = api;
