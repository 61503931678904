import React from 'react';

import { Box } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';

import { useSettings } from '@/hooks/useSettings.hook';

import { TopBanner } from '@/components/AdsBanner/TopBanner';
import { DesktopSideNavbar } from '@/components/DesktopSidebarNav';
import Footer from '@/components/Footer';
import { HeaderDesktop } from '@/components/Layout/HeaderDesktop';
import { DesktopNavigationBlock } from '@/components/Layout/Navigation/DesktopNavigationBlock';
import MobileNavigation from '@/components/Layout/Navigation/MobileNavigation';

import { RoutesEnum } from '@/enums/routes.enum';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

import { Header } from './Header';
import { Main } from './Layout.styles';
import Sidebar from './Sidebar';
import SidebarBottom from './SidebarBottom/SidebarBottom';
import SidebarContent from './SidebarContent';
import SidebarTop from './SidebarTop';

const Layout: React.FC = () => {
  const { isDesktop, isPageWithoutLayout } = useSettings();
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(false);
  const { pathname } = useLocation();

  const handleOpenSidebar = React.useCallback(
    () => setIsSidebarVisible(true),
    [setIsSidebarVisible],
  );

  const handleCloseSidebar = React.useCallback(
    () => setIsSidebarVisible(false),
    [setIsSidebarVisible],
  );

  React.useEffect(() => {
    const body = document.getElementById('body');
    if (isSidebarVisible && body) body.style.overflow = 'hidden';
    else if (!isSidebarVisible && body) body.style.overflow = 'auto';
  }, [isSidebarVisible]);

  React.useEffect(() => {
    handleCloseSidebar();
  }, [handleCloseSidebar, pathname]);

  const isRulesPages = pathname === RoutesEnum.rules;

  const renderHeader = () => {
    if (isDesktop) {
      return <HeaderDesktop />;
    }
    return <Header handleClick={handleOpenSidebar} />;
  };

  if (isPageWithoutLayout) {
    return (
      <>
        {renderHeader()}
        <Main>
          <Outlet />
        </Main>

        {isSidebarVisible && !IS_HEADER_HIDDEN && (
          <Sidebar
            BottomComponent={<SidebarBottom />}
            ContentComponent={<SidebarContent />}
            TopComponent={<SidebarTop />}
            isVisible={isSidebarVisible}
            onCloseClick={handleCloseSidebar}
          />
        )}
      </>
    );
  }

  return (
    <>
      {!IS_HEADER_HIDDEN && renderHeader()}

      {isSidebarVisible && !IS_HEADER_HIDDEN && (
        <Sidebar
          BottomComponent={<SidebarBottom />}
          ContentComponent={<SidebarContent />}
          TopComponent={<SidebarTop />}
          isVisible={isSidebarVisible}
          onCloseClick={handleCloseSidebar}
        />
      )}

      {isRulesPages ? (
        <Outlet />
      ) : (
        <>
          {!isDesktop && IS_HEADER_HIDDEN && <MobileNavigation />}

          <Main>
            {isDesktop && <DesktopNavigationBlock />}
            <Box
              background={isDesktop ? '#252525' : '#252525b0'}
              borderRadius='0 0 20px 20px'
              height='auto'
              minHeight='calc(100vh - 180px - 40px)'
            >
              <Outlet />
            </Box>

            <br />
            <br />
            <br />
          </Main>
        </>
      )}

      {/*{(isDesktop || !IS_HEADER_HIDDEN) && <Footer />}*/}

      <DesktopSideNavbar />
    </>
  );
};

export default Layout;
