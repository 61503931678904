import { FC, PropsWithChildren } from 'react';

import { useAuth } from '@/hooks/useAuth';

import LoaderScreen from '@/components/LoaderScreen';

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading } = useAuth();

  return (
    <>
      {children}
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default AuthWrapper;
