import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import '@/i18n';
import App from '@/App';
import { ChackraProvider } from '@/providers/ChackraProvider';
import { MetaProvider } from '@/providers/MetaProvider';
import theme from '@/providers/StyledComponentsProvider/theme/theme';
import { setupStore } from '@/store';

import { AppStyles, Reset } from '@/App/App.styles';
import FontStyles from '@/App/FontStyles';

import 'bootstrap/dist/css/bootstrap.min.css';

const store = setupStore();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ChackraProvider>
        <BrowserRouter>
          <FontStyles />
          <Reset />
          <AppStyles>
            <MetaProvider />
            <App />
          </AppStyles>
        </BrowserRouter>
      </ChackraProvider>
    </ThemeProvider>
  </Provider>,
);
