import React, { CSSProperties } from 'react';

import { Transition, TransitionStatus } from 'react-transition-group';

const duration = 400;

const defaultStyles: CSSProperties = {
  transition: `${duration}ms all ease-in-out`,
  height: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 999,
};

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { left: 0 },
  entered: { left: 0 },
  exiting: { left: '-400px' },
  exited: { left: '-400px' },
};

interface SidebarAnimationProps {
  children: React.ReactNode;
  inProp: boolean;
}

const SidebarAnimation: React.FC<SidebarAnimationProps> = ({
  children,
  inProp,
}) => {
  const nodeRef = React.useRef(null);
  return (
    <Transition in={inProp} nodeRef={nodeRef} timeout={duration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyles,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default SidebarAnimation;
