import { extendTheme } from '@chakra-ui/react';

import colors from '@/theme/foundations/colors';
import components from '@/theme/foundations/components';

export const theme = extendTheme({
  colors,
  components,
  styles: {
    global: {
      body: {
        color: 'white',
        background: '#222222',
      },
    },
  },
  breakpoints: {
    sm: '26.25rem',
    md: '48rem',
    lg: '62rem',
    xl: '80rem',
    '2xl': '96rem',
  },
});
