import { createGlobalStyle } from 'styled-components';

import BoldGilroyWoff from '@/fonts/Gilroy-Bold.woff';
import BoldGilroyWoff2 from '@/fonts/Gilroy-Bold.woff2';
import ExtraBoldGilroyWoff from '@/fonts/Gilroy-Extrabold.woff';
import ExtraBoldGilroyWoff2 from '@/fonts/Gilroy-Extrabold.woff2';
import MediumGilroyWoff from '@/fonts/Gilroy-Medium.woff';
import MediumGilroyWoff2 from '@/fonts/Gilroy-Medium.woff2';
import RegularGilroyWoff from '@/fonts/Gilroy-Regular.woff';
import RegularGilroyWoff2 from '@/fonts/Gilroy-Regular.woff2';

const FontStyles = createGlobalStyle`

  @font-face {
    font-family: "Gilroy";
    src: url(${RegularGilroyWoff2}) format("woff2"),
    url(${RegularGilroyWoff}) format("woff");

    font-weight: 400;
  }

  @font-face {
    font-family: "Gilroy";
    src: url(${MediumGilroyWoff2}) format("woff2"),
    url(${MediumGilroyWoff}) format("woff");

    font-weight: 500;
  }
  
  @font-face {
    font-family: "Gilroy";
    src: url(${BoldGilroyWoff2}) format("woff2"),
    url(${BoldGilroyWoff}) format("woff");
    
    font-weight: 700;
  }

  @font-face {
    font-family: "Gilroy";
    src: url(${ExtraBoldGilroyWoff2}) format("woff2"),
    url(${ExtraBoldGilroyWoff}) format("woff");

    font-weight: 800;
  }
    
`;

export default FontStyles;
