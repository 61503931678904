import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface StyledButtonProps {
  selected?: boolean;
}

export const StyledButton = styled(Link)<StyledButtonProps>(
  ({ theme, selected }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.space(2)} ${theme.space(2)}`,
    gap: '20px',
    background: selected
      ? '#999999'
      : 'linear-gradient(180deg, #323232 0%, #212121 100%)',
    fontSize: '16px',
    color: theme.colors.white,
    textDecoration: 'none',
    'svg path': {
      fill: selected ? 'white' : '#BDBDBD',
    },
  }),
);
