import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import ChevronLeft from '@/assets/icon/ChevronLeft';
import logo from '@/assets/images/Choctaw Logo.png';

import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import { LogoutButton } from '@/components/Layout/LogoutButton';

import { RoutesEnum } from '@/enums/routes.enum';

export const HeaderDesktop = () => {
  const { isDesktop, pagesWithBackButton } = useSettings();
  const { jwt } = useJWT();

  if (!isDesktop) return null;

  return (
    <Box
      as='header'
      left='0'
      position='absolute'
      top='0'
      width='100%'
      zIndex='997'
    >
      <Grid
        background='#050103'
        gridTemplateColumns='1fr 3fr 1fr'
        h='140px'
        padding='20px'
        width='100%'
      >
        <Flex>
          {pagesWithBackButton && <BackToLobbyButton />}
          {/*<BackToGamezoneButton />*/}
        </Flex>

        <Box as={Link} margin='0 auto' to={RoutesEnum.play}>
          <Image alt='logo' maxW='280px' src={logo} />
        </Box>

        <Flex align='center' justify='flex-end'>
          <LogoutButton />
        </Flex>
      </Grid>
    </Box>
  );
};

const BackToLobbyButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        align='center'
        as='button'
        bg='none'
        border='none'
        gap='10px'
        onClick={() => navigate(-1)}
      >
        <svg
          fill='none'
          height='40'
          viewBox='0 0 40 40'
          width='40'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.5119 8.82165C20.1628 9.47253 20.1628 10.5278 19.5119 11.1787L12.3571 18.3335H31.6667C32.5872 18.3335 33.3334 19.0797 33.3334 20.0002C33.3334 20.9206 32.5872 21.6668 31.6667 21.6668H12.3571L19.5119 28.8217C20.1628 29.4725 20.1628 30.5278 19.5119 31.1787C18.8611 31.8295 17.8058 31.8295 17.1549 31.1787L7.1549 21.1787C6.84234 20.8661 6.66675 20.4422 6.66675 20.0002C6.66675 19.5581 6.84234 19.1342 7.1549 18.8217L17.1549 8.82165C17.8058 8.17078 18.8611 8.17078 19.5119 8.82165Z'
            fill='white'
          />
        </svg>

        <Text color={'white'} fontSize='18px' fontWeight={700}>
          Lobby
        </Text>
      </Flex>
    </>
  );
};

const BackToGamezoneButton = () => {
  const { lang } = useSettings();

  return (
    <>
      <Flex
        _hover={{ textDecoration: 'underline' }}
        alignItems='center'
        as={Link}
        gap='8px'
        justifyContent='flex-start'
        outline='0'
        textDecoration='none'
        to={linkToGZ(lang)}
        w='fit-content'
      >
        <ChevronLeft />
        <Text color='white' fontSize='16px' mt='2px' textTransform='uppercase'>
          Gamezone
        </Text>
      </Flex>
    </>
  );
};
