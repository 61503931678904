import styled from 'styled-components';

import { useSettings } from '@/hooks/useSettings.hook';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

export const Main = styled('main')(() => {
  const { isDesktop, isMobile, isLockScreen } = useSettings();
  const calcPaddingTop = () => {
    if (IS_HEADER_HIDDEN && isDesktop) return '40px';
    if (!IS_HEADER_HIDDEN && isDesktop) return '180px';
    if (isMobile) return '0';
    return '20px';
  };

  return {
    width: '100%',
    maxWidth: isLockScreen ? 'min(1240px, 90vw)' : '690px',
    margin: '0 auto 40px',
    height: 'auto',
    paddingTop: calcPaddingTop(),
  };
});
