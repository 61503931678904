import React from 'react';

const ChevronLeft: React.FC = () => {
  return (
    <svg
      fill='none'
      height='20'
      viewBox='0 0 12 20'
      width='12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.67 1.77L9.89 0L0 9.9L9.9 19.8L11.67 18.03L3.54 9.9L11.67 1.77Z'
        fill='white'
      />
    </svg>
  );
};

export default ChevronLeft;
