import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from '@/assets/images/Choctaw Logo.png';

import { useSettings } from '@/hooks/useSettings.hook';

import AdsBanner from '@/components/AdsBanner';
import Navigation from '@/components/Layout/Navigation';

import { RoutesEnum } from '@/enums/routes.enum';

type IHeaderProps = {
  handleClick: () => void;
};

// Mobile Header
export const Header: React.FC<IHeaderProps> = ({ handleClick }) => {
  return (
    <div style={{ backgroundColor: '#252525' }}>
      <Box margin='0 auto' maxWidth='750px'>
        <MobileHeader onClick={handleClick} />
      </Box>

      {/*<AdsBanner />*/}
      <Navigation />
    </div>
  );
};

type HeaderLogoProps = {
  onClick: () => void;
};
export const MobileHeader: React.FC<HeaderLogoProps> = ({ onClick }) => {
  const { isLockScreen, isGameScreen } = useSettings();

  const isBurgerButtonHidden = isGameScreen || isLockScreen;

  return (
    <Flex alignItems='center' h='80px' justifyContent='center' width='100%'>
      {!isBurgerButtonHidden && (
        <Box
          as='button'
          background='none'
          border='none'
          left='25px'
          margin='0px'
          onClick={onClick}
          padding='0px'
          position='absolute'
        >
          <BurgerIcon />
        </Box>
      )}
      <Link to={RoutesEnum.play}>
        <Image alt='logo' src={logo} w='170px' />
      </Link>
    </Flex>
  );
};

const BurgerIcon: React.FC = () => (
  <svg
    fill='none'
    height='18'
    viewBox='0 0 24 18'
    width='24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.666672 1.91667C0.666672 1.13426 1.30094 0.5 2.08334 0.5H21.9167C22.6991 0.5 23.3333 1.13426 23.3333 1.91667C23.3333 2.69907 22.6991 3.33333 21.9167 3.33333H2.08334C1.30094 3.33333 0.666672 2.69907 0.666672 1.91667ZM0.666672 9C0.666672 8.2176 1.30094 7.58333 2.08334 7.58333H21.9167C22.6991 7.58333 23.3333 8.2176 23.3333 9C23.3333 9.7824 22.6991 10.4167 21.9167 10.4167H2.08334C1.30094 10.4167 0.666672 9.7824 0.666672 9ZM0.666672 16.0833C0.666672 15.3009 1.30094 14.6667 2.08334 14.6667H21.9167C22.6991 14.6667 23.3333 15.3009 23.3333 16.0833C23.3333 16.8657 22.6991 17.5 21.9167 17.5H2.08334C1.30094 17.5 0.666672 16.8657 0.666672 16.0833Z'
      fill='white'
    />
  </svg>
);
