const translation = {
  // Abbr rules text
  abr: 'AUCUN ACHAT NÉCESSAIRE. L’inscription démarre la veille du premier match de la Finale de la Coupe StanleyMD 2023 à 12 h (HE). Les participants doivent revenir sur le site Web pour participer à la promotion et recevoir leur carte de bingo à compter de la mise au jeu du match resectif pour lequel ils désirent inscrire et jusqu’à la fin de la deuxième période de chaque match repectif. Ce concours est ouvert uniquement aux résidents légaux des 50 États américains, du District de Columbia et du Canada qui détiennent un compte NHL.com et qui ont atteint l’âge de la majorité. Jusqu’à sept prix, un (1) prix par période d’inscription : un code cadeau de 500 USD échangeable sur NHLShop.com. Si le (ou la) gagnant(e) est Canadien(ne), il (ou elle) devra correctement répondre à une question réglementaire avant de recevoir un prix. Les probabilités de gagner dépendent du nombre de participations admissibles reçues. Nul là où interdit ou restreint par la loi. Cliquez sur nhl.com/fr/bingo pour les règlements officiels. Commanditaire : NHL Interactive CyberEnterprises, LLC.',
  abbrRulesTrademark:
    'NHL, l`emblème NHL, LNH, l`emblème LNH, le dessin de la Couple Stanley,et la marque sous forme de most «Stanley Cup» sont des marques de commerce déposées, et la marque sous forme de mots «Coupe Stanley» est une marque de commerce de la Ligue nationale de hockey. © LNH 2023. Tour droits rèservès. ',
  offRules: 'Règlements officiels',
  // Navigation pages
  play: 'JOUER',
  recent: 'RÉCENT',
  howToPlay: 'COMMENT JOUER',

  // Card
  signUp: 'Inscrivez-vous pour jouer',
  viewMy: 'Voir ma carte',
  view: 'Voiri la carte',
  registered: 'REGISTERED',
  entry: 'Entry',
  entries: 'Entries',
  contestClosed: 'BINGO CONTEST CLOSED',
  ifNecessary: 'IF NECESSARY',

  //Signup modal
  reg: 'Vous êtes inscrit(e) pour jouer au CHOCTAW T-BOX',
  rel: 'Votre carte sera révélée dans :',
  come: 'Revenez pour voir votre carte à la mise au jeu',
  priz: 'Prix',
  regen:
    'Les jetons se placent automatiquement sur la carte de Bingo pendant le match ',
  //Wait modal
  hangTight: 'HANG TIGHT!',
  cardToBeGenerated: 'Waiting for BINGO CARD TO BE GENERATED.',
  mayTakeUpFewMinutes: 'THIS CAN TAKE UP TO A FEW MINUTES',
  // How to play page
  vert: 'VERTICAL',
  horiz: 'HORIZONTAL',
  diag: 'DIAGONAL',
  fullCard: 'FULL CARD',
  earnEntriesIntoDraw:
    'EARN ENTRIES INTO A DRAW FOR A CHANCE TO WIN [PRIZE GOES HERE] BY SCORING ROWS ON YOUR BINGO CARD DURING A LIVE GAME.',
  autoScoresDuringGame: 'BINGO CARD AUTO-SCORES DURING GAME',
  clickForMoreInfo: 'For more information, click here for',
  SOG: 'SOG – Shot on Goal (any shot scored as a shot on goal. Does not include blocked shots or shots that hit the post)',
  PPG: 'PPG – Power Play Goal (a goal scored by a team playing with a man advantage. For example, a team that is playing at a man advantage of 5v4, 5v3, or 4v3)',
  SHG: 'SHG – Short-Handed Goal (a goal scored by a team with a player currently serving a penalty)',
  PIM: 'PIM – Penalties in Minutes',
  FOPercent: 'FO% - Face-off Percentage',
  PTS: 'PTS – Points (either a goal or an assist)',
  entryPerRow: 'ENTRY PER ROW',
  totalEntries: 'TOTAL ENTRIES',

  //Home page
  bingoMayTakeUp:
    'Cards may take up to 20 minutes post game to score all events',
  welcomeTo: 'Welcome to {{client}} Bingo!',
  reviewInRecent: 'Review your past cards in the recent tab',
  thanksForPlaying:
    'Thanks for playing {{client}} Bingo! Review your cards from the 2022-2023 season in the "Recent" tab',
  agreeToRules:
    'En signant, vous reconnaissez et vous acceptez de vous conformer aux',
  //Recent page
  noRecentContests: 'No recent contests',
  //Game field page
  gamePrizeText:
    'Each line you score in Bingo will earn you one entry into the random draw for the chance to win [prize goes here]. One winner will be selected at [time] on [date].',
  cardUpdateAfterPeriod: 'CARD WILL UPDATE AFTER EACH PERIOD',
  anyLine: 'ANY LINE',
  vertLines: 'VERT LINES',
  line: 'LINE',
  horzLines: 'HORZ LINES',

  //Onboarding page
  letsGetStarted: 'Let’s Get Started',
  makeSureToEnterSelections:
    'Make sure to enter your selections each day to compete for daily prizes. Remember to enter your selection for the tie breaker question before submitting your pick. You can edit your picks up until the start of the live game. Earn points for each correct prediction made.',
  playWithFriends:
    'Play with Friends. Who’s got game? Challenge your friends to play in a private league. Go to the leaderboard section, hit the Create button to start, and share the PIN code with your friends to join.',
  scoring: 'Scoring',
  challengeFriends: 'Challenge Friends',
  skip: 'skip',
  next: 'next',

  logout: 'Log out',
  returnToGamezone: 'Return to GameZone',
  rules: 'RULES',
  back: 'Back',

  bingo: 'Bingo',
  gamezone: 'Gamezone',
  justAFewMinutes: 'Just a few minutes, please refresh the page',
  fourCorners: 'FOUR CORNERS',
  rulesNotFound: 'Rules not found',
};
export default translation;
