import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import Background from '../Background';

import SidebarAnimation from './Sidebar.animation';
import { Bottom, Content, Wrapper } from './Sidebar.styles';
import { SidebarProps } from './types';

const Sidebar: React.FC<SidebarProps> = ({
  ContentComponent,
  onCloseClick,
  TopComponent,
  BottomComponent,
  isVisible,
}) => {
  return (
    <>
      <Background isVisible={isVisible} onCloseClick={onCloseClick} />
      <SidebarAnimation inProp={isVisible}>
        <Wrapper>
          <Flex direction='column' h='100%' justify='space-between'>
            <Box>
              {/* TOP */}
              <Flex
                alignItems='center'
                gap='20px'
                justifyContent='center'
                padding='42px 0'
              >
                {TopComponent}
              </Flex>
              <Content>{ContentComponent}</Content>
            </Box>
            <Bottom>{BottomComponent}</Bottom>
          </Flex>
        </Wrapper>
      </SidebarAnimation>
    </>
  );
};

export default Sidebar;
