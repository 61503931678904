import { FC, PropsWithChildren } from 'react';

import { Flex } from '@chakra-ui/react';
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom';

import { colors } from '@/providers/StyledComponentsProvider/theme/colors';

import { trackEvent } from '@/hooks/useGoogleAnalytics';
import { useNavLinks } from '@/hooks/useNavLinks';
import { useSettings } from '@/hooks/useSettings.hook';

import './style.css';

// Mobile top default navigation
const Navigation = () => {
  const { isPageWithoutNavigation } = useSettings();
  const { topNavLinks } = useNavLinks();

  if (isPageWithoutNavigation) return null;

  return (
    <Flex>
      {topNavLinks.map((item, index) => (
        <NavButton key={index} to={item.linkData.to}>
          {item.label}
        </NavButton>
      ))}
    </Flex>
  );
};

export default Navigation;

const NavButton: FC<PropsWithChildren & NavLinkProps> = ({
  children,
  to,
  ...rest
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    trackEvent('navigation_link_clicked', {
      link_url: to,
    });
  };

  return (
    <>
      <Flex
        align='center'
        as={NavLink}
        background={colors.primaryBlue}
        boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
        color={colors.textDark}
        height='43px'
        justify='center'
        letterSpacing='1px'
        onClick={onClick}
        textDecoration='none'
        width='100%'
        {...{ to }}
      >
        {children}
      </Flex>
    </>
  );
};
