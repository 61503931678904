import React from 'react';

import { Link } from 'react-router-dom';

import nhlBingoLogo from '@/assets/images/BINGO Logo.png';

import { RoutesEnum } from '@/enums/routes.enum';

const SidebarTop = () => {
  return (
    <>
      <Link style={{ maxWidth: '175px', width: '100%' }} to={RoutesEnum.play}>
        <img alt='logo' src={nhlBingoLogo} />
      </Link>
    </>
  );
};

export default SidebarTop;
