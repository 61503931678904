import React from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

import { IRoutes } from './types';

const HomeScreen = React.lazy(() => import('@/pages/HomeScreen'));
const RecentScreen = React.lazy(() => import('@/pages/RecentScreen'));
const HelpScreen = React.lazy(() => import('@/pages/HelpScreen'));
const LockScreen = React.lazy(() => import('@/pages/LockScreen'));
const GameFieldScreen = React.lazy(() => import('@/pages/GameField'));
const Rules = React.lazy(() => import('@/components/Rules'));
const RedirectCallbackScreen = React.lazy(
  () => import('@/pages/RedirectCallbackScreen'),
);

export const AppRoutes: IRoutes[] = [
  {
    path: RoutesEnum.game,
    element: <GameFieldScreen />,
  },
  {
    path: RoutesEnum.lock,
    element: <LockScreen />,
  },
  {
    path: RoutesEnum.rules,
    element: <Rules />,
  },
  {
    path: RoutesEnum.notFound,
    element: <div>not found</div>,
  },
  {
    path: RoutesEnum.play,
    element: <HomeScreen />,
  },
  {
    path: RoutesEnum.recent,
    element: <RecentScreen />,
  },
  {
    path: RoutesEnum.help,
    element: <HelpScreen />,
  },
  {
    path: RoutesEnum.redirectCallback,
    element: <RedirectCallbackScreen />,
  },
];
