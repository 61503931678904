import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { linkToGZ } from '@/utils';

import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsKeysEnum } from '@/enums/searchParamsKeys.enum';

import { FEATURE_NON_LOGIN_ENABLED, TOKEN_LOGOUT } from '@/utils/constants';

import { useGenerateJWTByTokenMutation } from '@/services/api.service';

export const useAuth = () => {
  const { jwt, setJWT, removeJWT } = useJWT();
  const { lang, setLang } = useSettings();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  const token = searchParams.get(SearchParamsKeysEnum.token);
  const urlLang = searchParams.get(SearchParamsKeysEnum.lang);

  const [triggerJWT, { isLoading }] = useGenerateJWTByTokenMutation();

  const handleLogin = async () => {
    try {
      const res = await triggerJWT({ token: token as string }).unwrap();
      setJWT(res.token);
      searchParams.delete(SearchParamsKeysEnum.token);
      setSearchParams(searchParams);
    } catch (e) {
      console.error('e', e);
    }
  };

  useEffect(() => {
    if (!FEATURE_NON_LOGIN_ENABLED && !token && !jwt && lang) {
      if (process.env.NODE_ENV === 'development') return;

      // return window.location.assign(linkToGZ(lang));
      return navigate(RoutesEnum.lock);
    }
  }, [jwt, lang, navigate, token]);

  useEffect(() => {
    if (urlLang) {
      i18n.changeLanguage(lang, () => setLang(urlLang));
      searchParams.delete(SearchParamsKeysEnum.lang);
      setSearchParams(searchParams);
    }
  }, [i18n, urlLang, searchParams, setLang, setSearchParams]);

  useEffect(() => {
    if (token && token === TOKEN_LOGOUT) {
      searchParams.delete(SearchParamsKeysEnum.token);
      setSearchParams(searchParams);
      removeJWT();
    }
    if (token && token !== TOKEN_LOGOUT) {
      removeJWT();
      handleLogin();
    }
    // eslint-disable-next-line
  }, [searchParams, setSearchParams, token]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return {
    isLoading,
  };
};
