const translation = {
  // Abbr rules text
  abr: 'NO PURCHASE NECESSARY. Registration begins 12:00 p.m. ET on the day prior to Game 1 of the 2023 Stanley Cup® Final. Participants must return to website to enter and receive their Bingo card starting at puck drop of the respective game(s) in which they are participating and ending at the end of the second period of each respective game. Open to legal residents of the 50 US, DC, and Canada, age of majority+ with an NHL.com account. Up to seven (7) prizes, one (1) prize per entry period: a $500 USD digital NHLShop.com gift code. If Canadian, skill test required to receive prize. Odds of winning depend upon the number of eligible entries received during each entry period. Void where prohibited or restricted by law. Visit nhl.com/bingo for Official Rules. Sponsor: NHL Interactive CyberEnterprises, LLC.',
  abbrRulesTrademark:
    'NHL, the NHL Shield, and image of the Stanley Cup are registered trademarks of the National Hockey League. © NHL 2023. All Rights Reserved.',
  offRules: 'Ametlikud Reeglid',
  // Navigation pages
  play: 'MÄNGI',
  recent: 'Hiljuti',
  howToPlay: 'KUIDAS MÄNGIDA',

  // Card
  signUp: 'MÄNGIMISEKS REGISTREERI',
  viewMy: 'VAATA ENDA KAARTI',
  view: 'VAATA KAARTI',
  registered: 'REGISTREERITUD',
  entry: 'Osaleja',
  entries: 'Osalejad',
  contestClosed: 'BINGO VÕISTLUS ON SULETUD',
  ifNecessary: 'KUI ON VAJALIK',

  //Signup modal
  reg: 'Oled registreeritud mängima CHOCTAW T-BOX',
  rel: 'Sinu kaardi avaldamiseni on jäänud:',
  come: 'Enda kaardi nägemiseks tule tagasi mängu alguseks',
  priz: 'Auhinnad',
  regen: 'BINGO KAARTI UUENDATAKSE MÄNGU JOOKSUL AUTOMAATSELT.',
  //Wait modal
  hangTight: 'PÜSI MEIEGA!',
  cardToBeGenerated: 'Oodake BINGO KAARDI LOOMIST.',
  mayTakeUpFewMinutes: 'SEE VÕTAB AEGA KUNI MÕNI MINUT',

  // How to play page
  vert: 'VERTIKAAL',
  horiz: 'HORISONTAAL',
  diag: 'DIAGONAAL',
  fullCard: 'KOGU KAART',
  earnEntriesIntoDraw:
    'SKOORIDES LIVE MÄNGUS RIDASID ENDA BINGO KAARDIL, SAAD TEENIDA PILETEID LOOSIMISEKS, KUS ON VÕIMALIK VÕITA [PRICE GOES HERE].',
  autoScoresDuringGame: 'BINGO KAARTI UUENDATAKSE MÄNGU JOOKSUL AUTOMAATSELT.',
  clickForMoreInfo: 'Lisainformatsiooni jaoks kliki',
  SOG: 'SOG - Vise väravale (iga vise loeb mis läheb väravaraamide vahele. Ei sisalda blokeeritud viskeid või posti tabatud viskeid)',
  PPG: 'PPG - Power play värav (värav, mis skooritakse enamuses mängiva tiimi poolt. Näiteks, kui tiim mängib 5v4, 5v3 või 4v3 enamuses)',
  SHG: 'SHG - Vähemuses skooritud värav (värav, mis skooritakse karistust kandva tiimi poolt)',
  PIM: 'PIM - Karistused kokku minutites',
  FOPercent: 'FO% - Lahtivisete võitmise protsent',
  PTS: 'PTS - Punktid (värav või resultatiivne sööt)',
  entryPerRow: 'OSALEJA REA KOHTA',
  totalEntries: 'OSALEJAID KOKKU',

  //Home page
  bingoMayTakeUp:
    'Bingo kaardite tulemuste kinnitamiseks võib kuluda kuni 20 minutit alates mängu lõpust',
  welcomeTo: 'Tere tulemast {{client}} Bingosse!',
  reviewInRecent: 'Vaata enda viimaseid kaarte "Hiljuti" vaate alt',
  thanksForPlaying:
    'Täname mängimast {{client}} Bingot! Vaata enda 2022-2023 hooaja kaarte "Hiljuti" vaate alt',
  agreeToRules: 'Registreerudes nõustud ja oled teadlik',

  //Recent page
  noRecentContests: 'Hiljutisi võistlusi ei ole',
  //Game field page
  gamePrizeText:
    'Iga skooritud liin Bingos annab sulle ühe osalemise loosimiseks, kus on võimalik võita [prize goes here]. Üks võitja valitakse [date] kell [time].',
  cardUpdateAfterPeriod: 'KAARTI UUENDATAKSE IGA PERIOODI JÄREL',
  anyLine: 'MISTAHES LIIN',
  vertLines: 'VERTIKAALSED LIINID',
  line: 'LIIN',
  horzLines: 'HORISONTAALSED LIINID',

  //Onboarding page
  letsGetStarted: 'Alustame',
  makeSureToEnterSelections:
    'Päevaauhindade eest võistlemiseks sisesta enda valikud iga päev. Pea meeles sisestada valik ka viigimurdja küsimusele. Saad muuta enda valikuid enne mängu algust. Punkte teenid iga õige ennustuse eest.',
  playWithFriends:
    'Mängi koos sõpradega. Kes on parim? Pane sõbrad proovile mängides privaatses liigas. Mine edetabeli sektsiooni, vajuta "Loo" nupule ja jaga PIN koodi sõpradele liitumiseks.',
  scoring: 'Punktiarvestus',
  challengeFriends: 'Pane sõbrad proovile',
  skip: 'jäta vahele',
  next: 'järgmine',

  logout: 'Logi välja',
  returnToGamezone: 'Mine tagasi Mängutsooni',
  rules: 'REEGLID',
  back: 'Tagasi',

  bingo: 'Bingo',
  gamezone: 'Mängutsoon',
  justAFewMinutes: 'Ainult mõned minutid, palun värskenda lehte',
  fourCorners: 'NELI NURKA',
  rulesNotFound: 'Reegleid ei leitud',
};
export default translation;
