import { ComponentStyleConfig } from '@chakra-ui/react';

const defaultButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  padding: '8px 27px',
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '17px',
  lineHeight: '20px',
  color: '#C9C9D7',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  outline: 'none',
  boxShadow: 'none',
  textDecoration: 'none',
};

export const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '17px',
      px: '12px',
      py: '8px',
    },
    md: {
      fontSize: '17px',
      px: '27px',
      py: '8px',
    },
  },
  variants: {
    primary: {
      ...defaultButtonStyles,
      background: '#121212',
      border: '2px solid',
      borderColor: '#01B182',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: '#01B182',
        color: 'button.secondary.text',
        _disabled: {
          background: '#01B182',
          outline: 'none',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: '#121212',
      border: '2px solid',
      borderColor: '#01B3D0',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: '#01B3D0',
        color: 'button.secondary.text',
        _disabled: {
          background: '#01B3D0',
          outline: 'none',
        },
      },
    },
    ghost: {
      ...defaultButtonStyles,
      _hover: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      _active: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'ghost',
  },
};
