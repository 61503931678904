import { useTranslation } from 'react-i18next';

import LangSwitcher from '@/components/LangSwitcher';
import { LogoutButton } from '@/components/Layout/LogoutButton';

import { RoutesEnum } from '@/enums/routes.enum';

import { tKeys } from '@/i18n/resourses';

import { List, StyledLink } from './SidebarBottom.styles';

const SidebarBottom = () => {
  const { t } = useTranslation();

  const links: Array<{ label: string; href: string }> = [
    {
      label: t(tKeys.rules),
      href: RoutesEnum.rules,
    },
    {
      label: t(tKeys.howToPlay),
      href: RoutesEnum.help,
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <List>
        {links.map((item, id) => (
          <li key={id}>
            <StyledLink to={item.href}>{item.label}</StyledLink>
          </li>
        ))}

        <LogoutButton />

        <div style={{ width: '100px' }}>{/*<LangSwitcher />*/}</div>
      </List>
    </div>
  );
};

export default SidebarBottom;
