const translation = {
  // Abbr rules text
  abr: 'NO PURCHASE NECESSARY. Registration begins 12:00 p.m. ET on the day prior to Game 1 of the 2023 Stanley Cup® Final. Participants must return to website to enter and receive their Bingo card starting at puck drop of the respective game(s) in which they are participating and ending at the end of the second period of each respective game. Open to legal residents of the 50 US, DC, and Canada, age of majority+ with an NHL.com account. Up to seven (7) prizes, one (1) prize per entry period: a $500 USD digital NHLShop.com gift code. If Canadian, skill test required to receive prize. Odds of winning depend upon the number of eligible entries received during each entry period. Void where prohibited or restricted by law. Visit nhl.com/bingo for Official Rules. Sponsor: NHL Interactive CyberEnterprises, LLC.',
  abbrRulesTrademark:
    'NHL, the NHL Shield, and image of the Stanley Cup are registered trademarks of the National Hockey League. © NHL 2023. All Rights Reserved.',
  offRules: 'Oficiālie Noteikumi',
  // Navigation pages
  play: 'SPĒLĒT',
  recent: 'NESEN',
  howToPlay: 'KĀ SPĒLĒT',

  // Card
  signUp: 'REĢISTRĒJIETIES, LAI SPĒLĒTU',
  viewMy: 'SKATĪT MANU KARTIŅU',
  view: 'SKATĪT KARTIŅU',
  registered: 'REĢISTRĒTS',
  entry: 'Dalībnieks',
  entries: 'Dalībnieki',
  contestClosed: 'BINGO KONKURSS IR SLĒGTS',
  ifNecessary: 'JA NEPIECIEŠAMS',

  //Signup modal
  reg: 'Jūs esat reģistrējies, lai spēlētu CHOCTAW T-BOX',
  rel: 'Jūsu karte tiks atklāta:',
  come: 'Atgriezieties, lai redzētu savu karti kad spēle ir sākusies',
  priz: 'Balvas',
  regen: 'BINGO KARTĪTE SPĒLES LAIKĀ AUTOMĀTISKI PIEŠĶIR PUNKTUS',
  //Wait modal
  hangTight: 'SATURIETIES!',
  cardToBeGenerated: 'Gaidām, kad tiks ģenerēta BINGO KARTE.',
  mayTakeUpFewMinutes: 'TAS VAR AIZŅEMT LĪDZ PAT DAŽĀM MINŪTĒM',

  // How to play page
  vert: 'VERTIKĀLI',
  horiz: 'HORIZONTĀLI',
  diag: 'DIAGONĀLI',
  fullCard: 'PILNA KARTĪTE',
  earnEntriesIntoDraw:
    'NOPELNĪT DALĪBU IZLOZĒ PAR IESPĒJU VINNĒT [PRIZE GOES HERE], LIVE SPĒLES LAIKĀ SAKRĀJOT RINDAS UZ SAVAS BINGO KARTĪTES.',
  autoScoresDuringGame:
    'BINGO KARTĪTE SPĒLES LAIKĀ AUTOMĀTISKI PIEŠĶIR PUNKTUS',
  clickForMoreInfo: 'Lai uzzinātu vairāk, noklikšķiniet šeit, lai skatītu',
  SOG: 'SOG - Metiens pa vārtiem (jebkurš metiens pa vārtiem. Neietver bloķētus metienus vai metienus, kas trāpīja pa vārtu stabu)',
  PPG: 'PPG - Vārti skaitliskajā vairākumā (vārti, ko komanda gūst, spēlējot skaitliskajā vairākumā). Piemēram, komanda, kas spēlē ar skaitlisko pārsvaru 5v4, 5v3 vai 4v3)',
  SHG: 'SHG - Vārti skaitliskajā mazākumā (vārti, kurus guvusi komanda, kuras spēlētājs izcieš sodu)',
  PIM: 'PIM - sodi minūtēs',
  FOPercent: 'FO% - Iemetienu procents',
  PTS: 'PTS - Punkti (vārti vai piespēles)',
  entryPerRow: 'IESNIEGŠANA VIENĀ RINDĀ',
  totalEntries: 'KOPĒJAIS DALĪBNIEKU SKAITS',

  //Home page
  bingoMayTakeUp:
    'Bingo kartēm var būt nepieciešamas līdz 20 minūtēm pēc spēles, lai reģistrētu visus notikumus',
  welcomeTo: 'Laipni lūdzam {{client}} Bingo!',
  reviewInRecent: 'Pārskatiet savas iepriekšējās kartes cilnē Nesenās',
  thanksForPlaying:
    'Paldies, ka spēlējat {{client}} Bingo! Pārskatiet savas 2022-2023. gada sezonas kartes sadaļā "Nesenās"',
  agreeToRules: 'Reģistrējoties jūs apstiprināt un piekrītat',

  //Recent page
  noRecentContests: 'Nesen notikušu konkursu nav',
  //Game field page
  gamePrizeText:
    'Par katru Bingo spēlē iegūto līniju jūs iegūsiet vienu ierakstu izlozē, lai iegūtu iespēju laimēt [prize goes here]. Viens uzvarētājs tiks izvēlēts [time], [date].',
  cardUpdateAfterPeriod: 'KARTE TIKS ATJAUNINĀTA PĒC KATRA PERIODA',
  anyLine: 'JEBKURA LĪNIJA',
  vertLines: 'VERTIKĀLĀS LĪNIJAS',
  line: 'LĪNIJA',
  horzLines: 'HORIZONTĀLĀS LĪNIJAS',

  //Onboarding page
  letsGetStarted: 'Sāksim',
  makeSureToEnterSelections:
    'Pārliecinieties, ka katru dienu ievadāt savu izvēli, lai sacenstos par ikdienas balvām. Neaizmirstiet ievadīt savu izvēli jautājumam par neizšķirtu atbildi, pirms iesniedzat savu izvēli. Savu izvēli varat rediģēt līdz pat tiešraides spēles sākumam. Pelniet punktus par katru pareizo prognozi.',
  playWithFriends:
    'Spēlējiet ar draugiem. Kam ir spēle? Izaiciniet draugus spēlēt privātā līgā. Dodieties uz līderu tablo sadaļu, nospiediet pogu Izveidot, lai sāktu, un kopīgojiet PIN kodu ar draugiem, lai pievienotos.',
  scoring: 'Vērtēšana',
  challengeFriends: 'Izaicini draugus',
  skip: 'izlaist',
  next: 'nākamais',

  logout: 'Izrakstīties',
  returnToGamezone: 'Atgriezties GameZone',
  rules: 'NOTEIKUMI',
  back: 'Atpakaļ',

  bingo: 'Bingo',
  gamezone: 'Gamezone',
  justAFewMinutes: 'Tikai dažas minūtes, lūdzu, atsvaidziniet lapu',
  fourCorners: 'ČETRI STŪRI',
  rulesNotFound: 'Noteikumi nav atrasti',
};
export default translation;
