import React, { SVGProps } from 'react';

const GameZone: React.FC<SVGProps<any>> = ({ fill = 'white' }) => {
  return (
    <svg
      fill='none'
      height='32'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.8577 6.02396L25.9772 1.14391C22.9744 -1.8586 15.1716 1.24137 8.21195 8.19706C4.96385 11.4482 2.42448 15.0283 1.05739 18.273C-0.386839 21.7155 -0.35007 24.4427 1.15814 25.9511L6.03887 30.8445C6.81137 31.6136 7.90297 32 9.24343 32L9.2432 31.9998C10.7905 31.9421 12.3114 31.5828 13.7207 30.942C16.9789 29.5716 20.5561 27.0293 23.7975 23.7845C30.7572 16.8285 33.8576 9.02983 30.8579 6.02384L30.8577 6.02396ZM2.30662 18.7868C3.60653 15.6901 6.04513 12.2644 9.17207 9.1337C14.3853 3.92775 19.622 1.33145 22.7626 1.33145C23.7434 1.33145 24.5227 1.58682 25.03 2.09381C25.91 2.97707 25.873 4.4448 25.7017 5.53983C25.1005 9.04278 22.1481 13.8021 17.9761 17.9668C13.8044 22.1316 9.06171 25.0769 5.54801 25.6917C4.46294 25.8798 2.98519 25.9168 2.10166 25.02C1.02668 23.9285 1.09392 21.6649 2.29637 18.7999L2.30662 18.7868ZM22.8634 22.8171C19.7361 25.9405 16.3098 28.3789 13.2094 29.6819C10.3476 30.881 8.08359 30.9515 6.99895 29.8733L4.22434 27.099H4.43943C4.63085 27.099 4.82907 27.099 5.03057 27.0688H5.13132C5.34641 27.0486 5.56455 27.0217 5.80305 26.9814C9.62218 26.3097 14.5363 23.2867 18.9431 18.8972C23.3504 14.5076 26.3532 9.58721 27.0082 5.76855C27.0485 5.53685 27.0755 5.31522 27.0989 5.09688C27.0973 5.069 27.0973 5.04089 27.0989 5.01301C27.119 4.80473 27.1258 4.60326 27.1291 4.40504V4.20356L29.9002 6.97791C32.0332 9.10052 29.7055 15.9923 22.8536 22.8373L22.8634 22.8171Z'
        fill={fill}
      />
    </svg>
  );
};

export default GameZone;
