const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    REACT_APP_GAMEZONE_URL: process.env.REACT_APP_GAMEZONE_URL,
    REACT_APP_PUSHER_APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
    REACT_APP_PUSHER_APP_CLUSTER: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    REACT_APP_FEATURE_NON_LOGIN_ENABLED:
      process.env.REACT_APP_FEATURE_NON_LOGIN_ENABLED,
    REACT_APP_PLAYER_TEAM_LOGO_BASE_URL:
      process.env.REACT_APP_PLAYER_TEAM_LOGO_BASE_URL,
    REACT_APP_PLAYER_LOGO_BASE_URL: process.env.REACT_APP_PLAYER_LOGO_BASE_URL,
    REACT_APP_ENABLED_LANGUAGES: process.env.REACT_APP_ENABLED_LANGUAGES,
    REACT_APP_TENANT: process.env.REACT_APP_TENANT,
    REACT_APP_ANY_LINE_ENTRIES: process.env.REACT_APP_ANY_LINE_ENTRIES,
    REACT_APP_X_ENTRIES: process.env.REACT_APP_X_ENTRIES,
    REACT_APP_T_ENTRIES: process.env.REACT_APP_T_ENTRIES,
    REACT_APP_FOUR_CORNERS_ENTRIES: process.env.REACT_APP_FOUR_CORNERS_ENTRIES,
    REACT_APP_FULL_CARD_ENTRIES: process.env.REACT_APP_FULL_CARD_ENTRIES,
    REACT_APP_IS_HEADER_HIDDEN: process.env.REACT_APP_IS_HEADER_HIDDEN,
  };
}

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;
export const GAMEZONE_URL = window.config.REACT_APP_GAMEZONE_URL as string;
export const PUSHER_APP_KEY = window.config.REACT_APP_PUSHER_APP_KEY as string;
export const PUSHER_APP_CLUSTER = window.config
  .REACT_APP_PUSHER_APP_CLUSTER as string;

export const FEATURE_NON_LOGIN_ENABLED =
  window.config.REACT_APP_FEATURE_NON_LOGIN_ENABLED === 'true';

export const PLAYER_TEAM_LOGO_BASE_URL = window.config
  .REACT_APP_PLAYER_TEAM_LOGO_BASE_URL as string;

export const PLAYER_CARD_LOGO_BASE_URL = window.config
  .REACT_APP_PLAYER_LOGO_BASE_URL as string;

export const ENABLED_LANGUAGES =
  (window.config.REACT_APP_ENABLED_LANGUAGES as string) || 'en';

export const TENANT = window.config.REACT_APP_TENANT as string;
export const ANY_LINE_ENTRIES = window.config.REACT_APP_ANY_LINE_ENTRIES || '1';

export const X_ENTRIES = window.config.REACT_APP_X_ENTRIES || '1';
export const T_ENTRIES = window.config.REACT_APP_T_ENTRIES || '1';
export const FOUR_CORNERS_ENTRIES =
  window.config.REACT_APP_FOUR_CORNERS_ENTRIES || '1';

export const FULL_CARD_ENTRIES =
  window.config.REACT_APP_FULL_CARD_ENTRIES || '1';

export const IS_HEADER_HIDDEN =
  window.config.REACT_APP_IS_HEADER_HIDDEN === 'true';

export const TOKEN_LOGOUT = 'logout';
export const GTM_TOKEN = process.env.REACT_APP_GTM_TOKEN;
