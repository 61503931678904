const translation = {
  // Abbr rules text
  abr: 'NO PURCHASE NECESSARY. Registration begins 12:00 p.m. ET on the day prior to Game 1 of the 2023 Stanley Cup® Final. Participants must return to website to enter and receive their Bingo card starting at puck drop of the respective game(s) in which they are participating and ending at the end of the second period of each respective game. Open to legal residents of the 50 US, DC, and Canada, age of majority+ with an NHL.com account. Up to seven (7) prizes, one (1) prize per entry period: a $500 USD digital NHLShop.com gift code. If Canadian, skill test required to receive prize. Odds of winning depend upon the number of eligible entries received during each entry period. Void where prohibited or restricted by law. Visit nhl.com/bingo for Official Rules. Sponsor: NHL Interactive CyberEnterprises, LLC.',
  abbrRulesTrademark:
    'NHL, the NHL Shield, and image of the Stanley Cup are registered trademarks of the National Hockey League. © NHL 2023. All Rights Reserved.',
  offRules: 'Official Rules',
  // Navigation pages
  play: 'PLAY',
  recent: 'RECENT',
  howToPlay: 'HOW TO PLAY',

  // Card
  signUp: 'SIGN UP TO PLAY',
  viewMy: 'VIEW MY CARD',
  view: 'VIEW CARD',
  registered: 'REGISTERED',
  entry: 'Entry',
  entries: 'Entries',
  contestClosed: 'BINGO CONTEST CLOSED',
  ifNecessary: 'IF NECESSARY',

  //Signup modal
  reg: 'You are registered to play CHOCTAW T-BOX',
  rel: 'Your Card will be revealed in:',
  come: 'Come back later to see your card',
  priz: 'Prizing',
  regen: 'Bingo card auto-scores during game',
  //Wait modal
  hangTight: 'HANG TIGHT!',
  cardToBeGenerated: 'Waiting for BINGO CARD TO BE GENERATED.',
  mayTakeUpFewMinutes: 'THIS CAN TAKE UP TO A FEW MINUTES',

  // How to play page
  vert: 'VERTICAL',
  horiz: 'HORIZONTAL',
  diag: 'DIAGONAL',
  fullCard: 'FULL CARD',
  earnEntriesIntoDraw:
    'EARN ENTRIES INTO A DRAW FOR A CHANCE TO WIN [PRIZE GOES HERE] BY SCORING ROWS ON YOUR BINGO CARD DURING A LIVE GAME.',
  autoScoresDuringGame: 'BINGO CARD AUTO-SCORES DURING GAME',
  clickForMoreInfo: 'For more information, click here for',
  SOG: 'SOG – Shot on Goal (any shot scored as a shot on goal. Does not include blocked shots or shots that hit the post)',
  PPG: 'PPG – Power Play Goal (a goal scored by a team playing with a man advantage. For example, a team that is playing at a man advantage of 5v4, 5v3, or 4v3)',
  SHG: 'SHG – Short-Handed Goal (a goal scored by a team with a player currently serving a penalty)',
  PIM: 'PIM – Penalties in Minutes',
  FOPercent: 'FO% - Face-off Percentage',
  PTS: 'PTS – Points (either a goal or an assist)',
  entryPerRow: 'ENTRY PER ROW',
  totalEntries: 'TOTAL ENTRIES',

  //Home page
  bingoMayTakeUp:
    'Cards may take up to 20 minutes post game to score all events',
  welcomeTo: 'Welcome to {{client}} Bingo!',
  reviewInRecent: 'Review your past cards in the recent tab',
  thanksForPlaying:
    'Thanks for playing {{client}} Bingo! Review your cards from the 2022-2023 season in the "Recent" tab',
  agreeToRules: 'By signing up you acknowledge and agree to the',

  //Recent page
  noRecentContests: 'No recent contests',
  //Game field page
  gamePrizeText:
    'Each line you score in Bingo will earn you one entry into the random draw for the chance to win [prize goes here]. One winner will be selected at [time] on [date].',
  cardUpdateAfterPeriod: 'CARD WILL UPDATE AFTER EACH PERIOD',
  anyLine: 'ANY LINE',
  vertLines: 'VERT LINES',
  line: 'LINE',
  horzLines: 'HORZ LINES',

  //Onboarding page
  letsGetStarted: 'Let’s Get Started',
  makeSureToEnterSelections:
    'Make sure to enter your selections each day to compete for daily prizes. Remember to enter your selection for the tie breaker question before submitting your pick. You can edit your picks up until the start of the live game. Earn points for each correct prediction made.',
  playWithFriends:
    'Play with Friends. Who’s got game? Challenge your friends to play in a private league. Go to the leaderboard section, hit the Create button to start, and share the PIN code with your friends to join.',
  scoring: 'Scoring',
  challengeFriends: 'Challenge Friends',
  skip: 'skip',
  next: 'next',

  logout: 'Log out',
  returnToGamezone: 'Return to GameZone',
  rules: 'RULES',
  back: 'Back',

  bingo: 'Bingo',
  gamezone: 'Gamezone',
  justAFewMinutes: 'Just a few minutes, please refresh the page',
  fourCorners: 'FOUR CORNERS',
  rulesNotFound: 'Rules not found',
};
export default translation;
