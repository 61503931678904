const translation = {
  // Abbr rules text
  abr: 'NO PURCHASE NECESSARY. Registration begins 12:00 p.m. ET on the day prior to Game 1 of the 2023 Stanley Cup® Final. Participants must return to website to enter and receive their Bingo card starting at puck drop of the respective game(s) in which they are participating and ending at the end of the second period of each respective game. Open to legal residents of the 50 US, DC, and Canada, age of majority+ with an NHL.com account. Up to seven (7) prizes, one (1) prize per entry period: a $500 USD digital NHLShop.com gift code. If Canadian, skill test required to receive prize. Odds of winning depend upon the number of eligible entries received during each entry period. Void where prohibited or restricted by law. Visit nhl.com/bingo for Official Rules. Sponsor: NHL Interactive CyberEnterprises, LLC.',
  abbrRulesTrademark:
    'NHL, the NHL Shield, and image of the Stanley Cup are registered trademarks of the National Hockey League. © NHL 2023. All Rights Reserved.',
  offRules: 'Официальные Правила',
  // Navigation pages
  play: 'ИГРАТЬ',
  recent: 'Недавние',
  howToPlay: 'КАК ИГРАТЬ',

  // Card
  signUp: 'ВОЙТИ ДЛЯ ИГРЫ',
  viewMy: 'СМОТРЕТЬ МОЮ КАРТУ',
  view: 'СМОТРЕТЬ КАРТУ',
  registered: 'ЗАРЕГИСТРИРОВАН',
  entry: 'Участник',
  entries: 'Участники',
  contestClosed: 'СОРЕВНОВАНИЕ BINGO ЗАКРЫТО',
  ifNecessary: 'ЕСЛИ НЕОБХОДИМО',

  //Signup modal
  reg: 'Вы зарегистрированы для игры в CHOCTAW T-BOX',
  rel: 'Ваша карта будет раскрыта через:',
  come: 'Возвращайтесь к началу игры, чтобы увидеть свою карту',
  priz: 'Призы',
  regen: 'КАРТА BINGO АВТОМАТИЧЕСКИ ОБНОВЛЯЕТСЯ ВО ВРЕМЯ ИГРЫ',
  //Wait modal
  hangTight: 'ПРОДОЛЖАЙТЕ!',
  cardToBeGenerated: 'Ожидание создания КАРТЫ BINGO.',
  mayTakeUpFewMinutes: 'ЭТО МОЖЕТ ЗАНЯТЬ ДО НЕСКОЛЬКИХ МИНУТ',

  // How to play page
  vert: 'ВЕРТИКАЛЬ',
  horiz: 'ГОРИЗОНТАЛЬ',
  diag: 'ДИАГОНАЛЬ',
  fullCard: 'ВСЯ КАРТА',
  earnEntriesIntoDraw:
    'ПОПАДИТЕ В РОЗЫГРЫШ, ЧТОБЫ ВЫИГРАТЬ [PRIZE GOES HERE], ЗАПОЛНЯЯ ЛИНИИ НА СВОЕЙ КАРТЕ БИНГО ВО ВРЕМЯ LIVE-ИГРЫ.',
  autoScoresDuringGame: 'КАРТА BINGO АВТОМАТИЧЕСКИ ОБНОВЛЯЕТСЯ ВО ВРЕМЯ ИГРЫ',
  clickForMoreInfo: 'Для получения подробной информации нажмите на',
  SOG: 'SOG – Бросок в створ ворот (любой бросок, засчитанный как бросок в створ ворот. Не включает блокированные или попавшие в штангу броски)',
  PPG: 'PPG – Гол в большинстве (гол, забитый командой, играющей с преимуществом в составе. Например, команда, которая играет с преимуществом в 5v4, 5v3 или 4v3)',
  SHG: 'SHG – Гол в меньшинстве (гол, забитый командой, игрок которой отбывает пенальти)',
  PIM: 'PIM – Штрафы в минутах',
  FOPercent: 'FO% — Процент вбрасываний',
  PTS: 'PTS – Очки (гол или результативная передача)',
  entryPerRow: 'УЧАСТНИК НА РЯД',
  totalEntries: 'ОБЩЕЕ КОЛ-ВО УЧАСТНИКОВ',

  //Home page
  bingoMayTakeUp:
    'Подтверждение результатов карты Bingo может занять до 20 минут после завершения игры',
  welcomeTo: 'Добро пожаловать в {{client}} Bingo!',
  reviewInRecent: 'Смотрите свои последние карты во вкладке «Недавние»',
  thanksForPlaying:
    'Благодарим за участие в {{client}} Bingo! Смотрите свои карты за сезон 2022-2023 во вкладке «Недавние»',
  agreeToRules:
    'Регистрируясь, Вы подтверждаете, что ознакомились и соглашаетесь с',

  //Recent page
  noRecentContests: 'Нет недавних игр',
  //Game field page
  gamePrizeText:
    'За каждую линию, заполненную в игре Bingo, Вы получите один шанс на участие в розыгрыше, где можно выиграть [prize goes here]. Один победитель будет выбран [date] в [time].',
  cardUpdateAfterPeriod: 'КАРТА ОБНОВЛЯЕТСЯ ПОСЛЕ КАЖДОГО ПЕРИОДА',
  anyLine: 'ЛЮБАЯ ЛИНИЯ',
  vertLines: 'ВЕРТИКАЛЬНЫЕ ЛИНИИ',
  line: 'ЛИНИЯ',
  horzLines: 'ГОРИЗОНТАЛЬНЫЕ ЛИНИИ',

  //Onboarding page
  letsGetStarted: 'Давайте начнём!',
  makeSureToEnterSelections:
    'Не забывайте вносить свои ответы каждый день для получения ежедневных призов. Не забудьте указать вопрос тай-брейкера до подтверждения своего выбора. Вы можете изменить свой выбор до начала игры. Зарабатывайте очки за каждый правильно сделанный прогноз.',
  playWithFriends:
    'Играйте с друзьями. Кто знает больше? Сразитесь в игре против своих друзей в приватной лиге. Перейдите в раздел таблицы лидеров, нажмите на кнопку Создать для начала и поделитесь PIN-кодом, чтобы Ваши друзья присоединились.',
  scoring: 'Подсчёт очков',
  challengeFriends: 'Бросьте вызов друзьям',
  skip: 'пропустить',
  next: 'далее',

  logout: 'Выйти',
  returnToGamezone: 'Вернуться в Игровую зону',
  rules: 'ПРАВИЛА',
  back: 'Назад',

  bingo: 'Bingo',
  gamezone: 'Игровая зона',
  justAFewMinutes: 'Всего несколько минут, пожалуйста, обновите страницу',
  fourCorners: 'ЧЕТЫРЕ УГЛА',
  rulesNotFound: 'Правила не найдены',
};
export default translation;
