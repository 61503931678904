import { useEffect, useLayoutEffect, useState } from 'react';

function canUseDOM(): boolean {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

const isBrowser = canUseDOM();

const useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect;

function useMediaQuery(query: string | string[]): boolean[] {
  const queries = Array.isArray(query) ? query : [query];
  const isSupported = isBrowser && 'matchMedia' in window;

  const [matches, setMatches] = useState(
    queries.map((query) =>
      isSupported ? window.matchMedia(query).matches : false,
    ),
  );

  useSafeLayoutEffect(() => {
    if (!isSupported) return undefined;

    const mediaQueryList = queries.map((query) => window.matchMedia(query));

    const listenerList = mediaQueryList.map((_, index) => {
      const listener = (mqlEvent: MediaQueryListEvent) => {
        const queryIndex = mediaQueryList.findIndex(
          (mediaQuery) => mediaQuery.media === mqlEvent.media,
        );

        setMatches((matches) => {
          const currentMatches = matches.map((x) => x);
          currentMatches[queryIndex] = mqlEvent.matches;
          return currentMatches;
        });
      };

      if (typeof mediaQueryList[index].addEventListener === 'function') {
        mediaQueryList[index].addEventListener('change', listener);
      } else {
        mediaQueryList[index].addListener(listener);
      }

      return listener;
    });

    return () => {
      mediaQueryList.forEach((_, index) => {
        if (typeof mediaQueryList[index].removeEventListener === 'function') {
          mediaQueryList[index].removeEventListener(
            'change',
            listenerList[index],
          );
        } else {
          mediaQueryList[index].removeListener(listenerList[index]);
        }
      });
    };
  }, []);

  return matches;
}

export default useMediaQuery;
