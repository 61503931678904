const colors = {
  navigationBg: '#051224',
  primaryOrange: '#F06424',
  primaryText: '#FFFFFF',
  incorrectRed: '#EA4451',
  correctGreen: '#68FF89',
  authBg: '#292934',
  primaryAccentGradient: 'linear-gradient(90deg, #CF1856, #A409A3)',
  borderImageGradient:
    'linear-gradient(to right, #9E07AE, #E5202F, #F06424, #FAA51A, #FAA51A)',
  imageBackground: 'rgba(255, 255, 255, 0.50)',
  mainGradient: 'linear-gradient(90deg, #E5202F 0%, #F06424 100%)',
  navigationGradient: 'linear-gradient(180deg, #3E3E56 0%, #18181D 100%)',
};

export default colors;
