import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

export const useJWT = () => {
  const {
    value: jwt,
    setItem: setJWT,
    removeItem: removeJWT,
  } = useLocalStorage<string>(StorageKeysEnum.jwt);

  return { jwt, setJWT, removeJWT };
};
