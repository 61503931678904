import { GTM_TOKEN } from '@/utils/constants';

export function triggerGTAGEvent(
  eventName: string,
  additionalOptions: any,
): void {
  if (Boolean(GTM_TOKEN)) {
    const body = {
      event: eventName,
      ...additionalOptions,
    };
    console.log('🚀 ~> triggerGTAGEvent push:', body);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(body);
  }
}
