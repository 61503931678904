import React, { PropsWithChildren } from 'react';

import { Box } from '@chakra-ui/react';

import background from '@/assets/images/Choctaw_Background.png';

export const GlobalBackground: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      alignItems='center'
      backgroundImage={`url(${background})`}
      backgroundPosition='center center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      justifyContent='center'
      minHeight='100vh'
      position='relative'
    >
      {children}
    </Box>
  );
};
