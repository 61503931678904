import { Button } from '@chakra-ui/react';

import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import { GAMEZONE_URL } from '@/utils/constants';

export const LogoutButton = () => {
  const { jwt, removeJWT } = useJWT();
  const { lang } = useSettings();

  const handleLogout = () => {
    removeJWT();
    window.location.assign(`${GAMEZONE_URL}/login?lang=${lang}&token=logout`);
  };

  if (!jwt) return null;

  return (
    <Button
      onClick={handleLogout}
      size='md'
      textTransform='uppercase'
      variant='primary'
    >
      Log out
    </Button>
  );
};
